<!--
 * @Author: your name
 * @Date: 2021-03-16 09:46:20
 * @LastEditTime: 2021-03-25 10:43:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sun_highschool\src\components\res\RouterTheme\ResNormalRouter.vue
-->
<template>
<div class='res res-sun' :class="{isStu:getRole == '学生'}">
    <stu-nav v-if="getRole == '学生'"></stu-nav>
    <kczx-nav></kczx-nav>
    <!-- <keep-alive :exclude='excludeList'>
    </keep-alive> -->
        <router-view @join-class='joinClass' class='container' :key="$route.path" :Platform="Platform" :style='footPosition' />
    <my-shadow />
    <BackTop></BackTop>
</div>
</template>

<script>
    import myShadow from '../Common/Layout/Shadow.vue'
    import KczxNav from '../Common/Layout/KczxNav'
    import StuNav from '../Common/Layout/StuNav'
    export default {
        props: ['Platform'],
        components: {myShadow,KczxNav,StuNav},
        data() {
            return {
                isNeedRefresh:false,
                isShowCreate: true,
            }
        },
        methods:{
            joinClass(){
                this.isNeedRefresh = 'true'+new Date().getTime()
            },
        },
        watch: {
            $route(to,from){
                let flag = to.query.updateflag
                if(this.isValid(flag)){// ["applicationdetail", "search"] 默认有这两个路由不进行缓存
                    if(flag == 'lecture'){ // 同步资源以及微课视频
                        this.$store.commit('ADD_RES_KEEPALIVE_LIST', ['myresources','lectureresources'])
                    }else if(flag == 'exam'){ // 试卷专区
                        this.$store.commit('ADD_RES_KEEPALIVE_LIST', ['examinationpaperarea'])
                    }else if(flag == 'app'){ // 应用资源
                        this.$store.commit('ADD_RES_KEEPALIVE_LIST', ['applicationresources'])
                    }
                }
            }
        },
        computed: {
            footPosition() {
                let innerHeight = window.innerHeight;
                let contentHeight = innerHeight - 260;
                return "min-height: " + contentHeight + "px";
            },
            excludeList() {
                let excludeList = this.$store.state.res.excludeList
                return excludeList
            },
            getRole(){
                return this.loadAccount()?this.loadAccount().role:null
            }
        },
        created(){
            this.Bus.$emit('onMenuChanged', 'res')   
        }
    }
</script>
<style scoped>
.no-nav{
    padding-top: 20px;
}
.res{
    display: flex;
}
.isStu{
    display: block;
}
</style>
