<template>
    <div class="shadow" v-if='$store.state.res.shadow'></div>
</template>

<script>
export default {
}
</script>

<style scoped>
    .shadow{
        position: fixed;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .4);
        z-index: 900;
    }
</style>
