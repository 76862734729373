<template>
    <div class='StuNav'>
        <div class="stu-nav-box">
            <!-- 2023-01-05 修改导航 -> 旧 -->
            <!-- <router-link tag="span" :to="'/Res/Tbkc'" :class="{isActive:$route.path == '/Res/Tbkc'}">同步课程</router-link>
            <router-link v-if="!otherZoneNav" tag="span" :to="'/Res/Ztxx'" :class="{isActive:$route.query.type == 'Ztxx' || $route.path == '/Res/Ztxx'}">{{ periodName }}</router-link> -->
            <!-- 新 -->
            <div v-for="(item, index) in navList" :key="index">
                <template v-if="item.path == '/Res/Ztxx'">
                    <router-link tag="span" :to="item.path" :courseid="item.courseId" :class="{ 'nav-course': true, isActive: $route.query.type == 'Ztxx' || $route.path == item.path }">{{ item.name }}</router-link>
                </template>
                <template v-else>
                    <router-link tag="span" :to="item.path" :courseid="item.courseId" :class="{ 'nav-course': true, isActive: $route.path == item.path }">{{ item.name }}</router-link>
                </template>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'StuNav',
    props:[],
    components:{},
    data(){
        return {
            navList: [],
        }
    },
    computed:{
        // 2023-01-05 修改导航 -> 旧
        // periodName() {
        //     return this.getSessionVal('sc_period') == 3 && this.loadAccount().zoneidx == 62 ? '专题学习' : '特色课程';
        // },
        // otherZoneNav() {
        //     let zoneidx = this.loadAccount().zoneidx;
        //     return this.getSessionVal('sc_period') == 2 && (zoneidx == 121346 || zoneidx == 120449 || zoneidx == 120276);
        // }
    },
    watch:{},
    created(){
        this.initTopNav();
    },
    mounted(){},
    methods:{
        getRoleId() {
            let { role } = this.loadAccount();
            let roleId = 0;
            switch (role) {
                case "系统管理员": roleId = 1; break;
                case "区域管理员": roleId = 2; break;
                case "学校管理员": roleId = 3; break;
                case "教师": roleId = 4; break;
                case "学生": roleId = 5; break;
                case "家长": roleId = 6; break;
                default: break;
            }
            return roleId;
        },
        getNavList(data) {
            const navList = [
                {name:'同步课程',path:'/Res/Tbkc'},
                {name:'特色课程',path:'/Res/Ztxx'},
                {name:'专题学习',path:'/Res/Ztxx'},
            ];

            const result = [];
            navList.forEach(item => {
                data.some(nav => {
                if (nav.name == item.name) {
                    return result.push(Object.assign({}, nav, item));
                }
                });
            });

            // 处理 金太阳琼海中学 隐藏 特色课程
            const schoolId = this.loadAccount().schoolidx;
            if (schoolId == 342095) {
                result.some((item, index) => {
                    if (item.name == "特色课程") {
                        result.splice(index, 1)
                        return true;
                    }
                });
            }

            return result;
        },
        initTopNav() {
            let param = {
                PeriodID: this.getSunAccount('sc_period'),
                RoleId: this.getRoleId(),
                ZoneID: this.getSessionVal("sc_zone")
            }
            let api = this.getMasterDataCenterUrl() + '/api/CourseConfig/GetCourseModuleConfig?' + $.param(param)
            this.$http.get(api).then(res => {
                const data = res.data.data;
                if (this.isValidArray(data)) {
                    this.navList = this.getNavList(data);
                    this.$store.commit('UPDATE_LOADEND');
                    let idx = data.findIndex(i => i.name == "专题学习" || i.name == "特色课程")
                    if (idx != -1) {
                        sessionStorage.setItem('stu_courseId', data[idx].courseId);
                    }
                }
            });
        },
    }
}
</script>

<style lang='less' scoped>
.StuNav{
    padding: 0 15px;
}
.stu-nav-box{
    margin: 15px 0;   
    width: 100%;
    height: 50px;
    background: #FFF;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    span{
        display: inline-block;
        font-size: 16px;
        color: #4A4A4A;
        font-weight: 400;
        cursor: pointer;
        margin-left: 70px;
        line-height: 50px;
        user-select: none;
    }
}
.isActive{
    color: #22B5AD !important;
    font-weight: 500 !important;
}
</style>
